var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-8 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-semibold text-green-600"
  }, [_vm._v(" " + _vm._s(_vm.$t("update_shipping")) + " ")])])]), _c('div', {
    staticClass: "px-6 mt-3"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex space-x-2 w-full shadow-xs mt-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-3 w-full p-2"
  }, [_c('div', {
    staticClass: "w-full flex text-base font-semibold text-gray-600"
  }, [_c('p', {
    staticClass: "w-1/2"
  }, [_vm._v(_vm._s(_vm.$t("shipping_informations")))])]), _c('div', {
    staticClass: "bg-white rounded-lg p-6 pb-0"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("address_shipping"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.address,
      expression: "formData.address"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "address", $event.target.value);
      }
    }
  })])]), _vm.$route.params.id && (_vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'StockManager') ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "shippdate"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.date,
      expression: "formData.date"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "shippdate",
      "type": "date"
    },
    domProps: {
      "value": _vm.formData.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "date", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.formData.country && _vm.formData.country != 'MA' ? _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "category"
    }
  }, [_vm._v(_vm._s(_vm.$t("zone_shipping")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: _vm.currentUser.type == 'Courier'
    },
    attrs: {
      "label": "name",
      "options": _vm.zones
    },
    model: {
      value: _vm.formData.zone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "zone", $$v);
      },
      expression: "formData.zone"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: true
    },
    attrs: {
      "options": _vm.options,
      "label": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1)]), _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "category"
    }
  }, [_vm._v(_vm._s(_vm.$t("courier")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: _vm.currentUser.type == 'Courier'
    },
    attrs: {
      "label": "fullName",
      "options": _vm.couriers
    },
    on: {
      "search": function search($event) {
        return _vm.searchCouriers($event, 'hh');
      }
    },
    model: {
      value: _vm.formData.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courier", $$v);
      },
      expression: "formData.courier"
    }
  })], 1)]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerFullName"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.customer.city,
      expression: "formData.order.customer.city"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "city",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.customer.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order.customer, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.status,
      expression: "formData.status"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    attrs: {
      "name": "status",
      "autocomplete": "type",
      "disabled": _vm.formData.status && (_vm.formData.status === 'processed' || _vm.formData.status === 'paid')
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChangeStatus($event);
      }]
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _vm._l(_vm.statusShipping, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])]), _vm.formData.status == 'cancelled' || _vm.formData.status == 'refused' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("cancelatation_comment_shipping")))])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.comment,
      expression: "formData.comment"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "comment", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.formData.type != 'normal' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "type"
    }
  }, [_vm._v(_vm._s(_vm.$t("type")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.type,
      expression: "formData.type"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formData, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("type")) + " ")]), _vm._l(_vm.typesShipp, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])]) : _vm._e()])])])])])]), _c('div', {
    staticClass: "tab-cc w-full s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale"
  }, [_c('div', {
    staticClass: "d-block p-0 m-0"
  }, [_c('div', {
    staticClass: "s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1"
  }, [_c('svg', {
    staticClass: "fill-current mr-2",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
    }
  })]), _vm._v(" Change in order of cases ")]), _c('div', {
    staticClass: "s-sidebarwidget--item"
  }, [_c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(1), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(2)]), _c('tr', [_vm._m(3), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(4)])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(5), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(6)]), _c('tr', [_vm._m(7), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(8)]), _c('tr', [_vm._m(9), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(10)]), _c('tr', [_vm._m(11), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(12)])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(13), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(14)]), _c('tr', [_vm._m(15), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(16)]), _c('tr', [_vm._m(17), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(18)])])])])])])]), _vm.formData.status == 'return' ? _c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg",
    attrs: {
      "id": _vm.formData._id
    }
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(19), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("products_returned")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("image")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v("Name" + _vm._s(_vm.$t("name")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_sent")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_received")) + " ")])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.formData.order.details, function (it, index) {
    return _c('tr', {
      key: index,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('span', [_vm._v(_vm._s(it.quantity))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_c('thead', [_c('tr', [_c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("ok_good")) + " ")]), _vm.CheckQteReturnedexist ? _c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("defective")) + " ")]) : _vm._e()])]), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.product.qteChange,
        expression: "it.product.qteChange"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckQteReturnedexist,
        "type": "number",
        "max": it.quantity,
        "min": "0"
      },
      domProps: {
        "value": it.product.qteChange
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.product, "qteChange", $event.target.value);
        }
      }
    })])]), _vm.CheckQteReturnedexist ? _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(it.quantity - it.product.qteChange) + " ")])]) : _vm._e()])])])])]);
  }), 0)])])])])])]) : _vm._e(), _vm.formData.type == 'change' ? _c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(20), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("products_change")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("image")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("quantity")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_returned")) + " ")]), _vm.CheckQteDefecteuseexist ? _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_defective")) + " ")]) : _vm._e()])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.formData.order.details, function (it, index) {
    return _c('tr', {
      key: index,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.quantity))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.product.qteChange,
        expression: "it.product.qteChange"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckQteDefecteuseexist,
        "type": "number",
        "max": it.quantity,
        "min": "0"
      },
      domProps: {
        "value": it.product.qteChange
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.product, "qteChange", $event.target.value);
        }
      }
    })]), _vm.CheckQteDefecteuseexist ? _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.quantity - it.product.qteChange))])]) : _vm._e()]);
  }), 0)])])])])])]) : _vm._e(), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center p-10"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])]), _c('div', {
    staticClass: "flex justify-center p-10"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none btn-more text-white hover:text-red-500",
    class: _vm.$colors.newColor,
    on: {
      "click": _vm.startConfirmation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("more_info")) + " "), _c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "transition": "0.3s"
    },
    style: _vm.showMoreInfo ? 'transform: rotate(180deg)' : ''
  }, [_vm._v("keyboard_arrow_down")])])]), _vm.showMoreInfo ? _c('div', [_c('div', {
    staticClass: "flex w-full space-x-2"
  }, [_c('div', {
    staticClass: "flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(21), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("customer_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerFullName"
    }
  }, [_vm._v(_vm._s(_vm.$t("full_name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.customer.fullName,
      expression: "formData.order.customer.fullName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "customerFullName",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.customer.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order.customer, "fullName", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.customer.phone,
      expression: "formData.order.customer.phone"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "customerPhone",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.customer.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order.customer, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("adress")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.customer.address,
      expression: "formData.order.customer.address"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.customer.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order.customer, "address", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerShippingAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_address")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.customer.shippingAddress,
      expression: "formData.order.customer.shippingAddress"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerShippingAddress",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.customer.shippingAddress
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order.customer, "shippingAddress", $event.target.value);
      }
    }
  })])])])])])]), _c('div', {
    staticClass: "flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-2"
  }, [_vm._m(22), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("order_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "source"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_source")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.source,
      expression: "formData.order.source"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "source",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.source
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order, "source", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "sourceLink"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_source_link"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.sourceLink,
      expression: "formData.order.sourceLink"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "sourceLink",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.order.sourceLink
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order, "sourceLink", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "date"
    }
  }, [_vm._v(_vm._s(_vm.$t("order_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.order.date,
      expression: "formData.order.date"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "date",
      "disabled": "",
      "type": "datetime-local"
    },
    domProps: {
      "value": _vm.formData.order.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.order, "date", $event.target.value);
      }
    }
  })])]), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.seller.fullName,
      expression: "formData.seller.fullName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "date",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.seller.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.seller, "fullName", $event.target.value);
      }
    }
  })])]) : _vm._e()])])])])]), _c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "flex space-x-2 w-full shadow-xs mt-1 rounded-lg mb-10"
  }, [_c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(23), _c('div', {
    staticClass: "ml-3 w-full p-2 pb-8"
  }, [_c('div', {
    staticClass: "w-full flex text-base font-semibold text-gray-600"
  }, [_c('p', {
    staticClass: "w-1/2"
  }, [_vm._v(_vm._s(_vm.$t("order")))])]), _c('div', {
    staticClass: "mt-4 overflow-x-auto"
  }, [_vm.formData.order.details ? _c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("image")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("unit_price")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity")) + " ")]), _c('th', {
    staticClass: "px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("amount")) + " ")])])]), _vm._l(_vm.formData.order.details, function (item, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_c('img', {
      staticClass: "h-16 w-16 rounded-md",
      attrs: {
        "src": item.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.product.name) + " ")])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.unitPrice) + " "), _c('sup', {
      staticClass: "text-black"
    }, [_vm._v(_vm._s(_vm.currentCurrency))])])]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex space-x-6"
    }, [_c('span', {
      staticClass: "px-1 text-gray-700 text-sm rounded-md w-4 h-6 flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary
    }, [_vm._v(" " + _vm._s(item.quantity) + " ")])]), _c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    })]), _c('td', {
      staticClass: "px-6 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.unitPrice * item.quantity) + " "), _c('sup', {
      staticClass: "text-black"
    }, [_vm._v(_vm._s(_vm.currentCurrency))])])])])]);
  }), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "px-6 text-right py-2 whitespace-no-wrap border-gray-200",
    attrs: {
      "colspan": "5"
    }
  }, [_c('div', {
    staticClass: "text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + ": " + _vm._s(_vm.TotalPrice()) + " "), _c('sup', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.currentCurrency))])])])])])], 2) : _vm._e()])])])])])])]) : _vm._e()])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-500"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("OutOfStock")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("OutOfStock")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Prepared")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Prepared")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Shipped")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Paid")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Cancelled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Refused")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Cancelled Or Refused Or Paid")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Return")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_grocery_store")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-500"
  }, [_vm._v("local_mall")])]);

}]

export { render, staticRenderFns }