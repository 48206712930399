<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-8 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <button :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()">
            <i class="material-icons">chevron_left</i>
          </button>
          <div class="text-xl font-semibold text-green-600">
            {{ $t("update_shipping") }}
          </div>
        </div>
      </div>

      <div class="px-6 mt-3">
        <!-- ****************************************Shipping information********************************* -->
        <div class="w-full">
          <div class="flex space-x-2 w-full shadow-xs mt-1 mb-1 rounded-lg">
            <div class="flex w-full justify-between items-center h-auto p-6">
              <div class="flex w-full">
                <span class="rounded-full h-12 w-12 bg-gray-200">
                  <i class="material-icons p-3 text-green-500">local_mall</i>
                </span>
                <div class="ml-3 w-full p-2">
                  <div class="w-full flex text-base font-semibold text-gray-600">
                    <p class="w-1/2">{{ $t("shipping_informations") }}</p>
                  </div>
                  <div class="bg-white rounded-lg p-6 pb-0">
                    <div class="grid lg:grid-cols-2 gap-6">
                      <div
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="customerAddress" class="bg-white text-gray-700 px-1">{{ $t("address_shipping")
                              }}<span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <textarea id="customerAddress" type="text" v-model="formData.address"
                            class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                        </p>
                      </div>
                      <div v-if="
                        $route.params.id &&
                        (currentUser.type == 'Admin' ||
                          currentUser.type == 'CEO' ||
                          currentUser.type == 'StockManager')
                      "
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="shippdate" class="bg-white text-gray-700 px-1">{{ $t("shipping_date") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <input id="shippdate" type="date" v-model="formData.date"
                            class="py-2 text-xs px-2 outline-none block h-full w-full" />
                        </p>
                      </div>
                      <div v-if="formData.country && formData.country != 'MA'"
                        class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="category" class="bg-white text-gray-700 px-1">{{ $t("zone_shipping") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <v-select v-bind:class="{
                            disabled: currentUser.type == 'Courier',
                          }" label="name" v-model="formData.zone" :options="zones"></v-select>
                        </p>
                      </div>
                      <div
                        class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="country" class="bg-white text-gray-700 px-1">{{ $t("country") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <v-select v-model="formData.country" :options="options" v-bind:class="{ disabled: true }"
                            label="name">
                            <template slot="option" slot-scope="option">
                              <img class="flag-img" :src="$f.getCountryCode(option.code)" />
                              {{ option.name }}
                            </template>
                          </v-select>
                        </p>
                      </div>
                      <div
                        class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="category" class="bg-white text-gray-700 px-1">{{ $t("courier") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <v-select v-bind:class="{
                            disabled: currentUser.type == 'Courier',
                          }" label="fullName" @search="searchCouriers($event, 'hh')" v-model="formData.courier"
                            :options="couriers"></v-select>
                        </p>
                      </div>
                      <div
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="customerFullName" class="bg-white text-gray-700 px-1">{{ $t("city") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <input id="city" disabled type="text" v-model="formData.order.customer.city"
                            class="py-2 text-xs px-2 outline-none block h-full w-full" />
                        </p>
                      </div>
                      <div
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="status" class="bg-white text-gray-700 px-1">{{ $t("status") }}
                              <span :class="$colors.required">*</span>
                            </label>
                          </p>
                        </div>
                        <p>
                          <select name="status" autocomplete="type" @change="onChangeStatus($event)" :disabled="formData.status &&
                            (formData.status === 'processed' ||
                              formData.status === 'paid')
                            " v-model="formData.status"
                            class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                            <option class="bg-white" selected :value="null">
                              {{ $t("status") }}
                            </option>
                            <option class="bg-white text-gray-600" v-for="(value, index) in statusShipping" :key="index"
                              :value="value">
                              {{ value }}
                            </option>
                          </select>
                        </p>
                      </div>
                      <div v-if="
                        formData.status == 'cancelled' ||
                        formData.status == 'refused'
                      "
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="customerAddress" class="bg-white text-gray-700 px-1">{{
                              $t("cancelatation_comment_shipping")
                              }}</label>
                          </p>
                        </div>
                        <p>
                          <textarea id="customerAddress" type="text" v-model="formData.comment"
                            class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                        </p>
                      </div>
                      <div v-if="formData.type != 'normal'"
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="type" class="bg-white text-gray-700 px-1">{{ $t("type") }}
                              <span :class="$colors.required">*</span>
                            </label>
                          </p>
                        </div>
                        <p>
                          <select v-model="formData.type"
                            class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                            <option class="bg-white" selected :value="null">
                              {{ $t("type") }}
                            </option>
                            <option class="bg-white text-gray-600" v-for="(value, index) in typesShipp" :key="index"
                              :value="value">
                              {{ value }}
                            </option>
                          </select>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-cc w-full s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale">
            <div class="d-block p-0 m-0">
              <div
                class="s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1">
                <svg xmlns="http://www.w3.org/2000/svg" class="fill-current mr-2" width="20" height="20"
                  viewBox="0 0 24 24">
                  <path
                    d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
                </svg>
                Change in order of cases
              </div>
              <div class="s-sidebarwidget--item">
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>To prepare</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>OutOfStock</b></td>
                    </tr>
                    <tr>
                      <td><b>OutOfStock</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>To prepare</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr-grey" />
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>To prepare</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Prepared</b></td>
                    </tr>
                    <tr>
                      <td><b>Prepared</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Shipped</b></td>
                    </tr>
                    <tr>
                      <td><b>Shipped</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Delivered</b></td>
                    </tr>
                    <tr>
                      <td><b>Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Paid</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr-grey" />
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>Shipped Or Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Cancelled</b></td>
                    </tr>
                    <tr>
                      <td><b>Shipped Or Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Refused</b></td>
                    </tr>
                    <tr>
                      <td><b>Cancelled Or Refused Or Paid</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Return</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- shipping cancelled -->
        <div :id="formData._id" v-if="formData.status == 'return'"
          class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
          <div class="flex w-full mt-1">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400">local_mall</i>
            </span>
            <div class="ml-5 w-full pb-4">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("products_returned") }}
              </div>
              <div class="w-full space-y-4">
                <div class="w-full">
                  <table class="min-w-full w-full">
                    <thead class="text-white">
                      <tr
                        class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                        <th class="p-2 justify-center">{{ $t("image") }}</th>
                        <th class="p-2 justify-center">Name{{ $t("name") }}</th>
                        <th class="p-2 justify-center">
                          {{ $t("quantity_sent") }}
                        </th>
                        <th class="p-2 justify-center">
                          {{ $t("quantity_received") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="flex-1 sm:flex-none">
                      <tr v-for="(it, index) in formData.order.details" :key="index"
                        class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                          <div>
                            <img :alt="it.product.name" :src="it.product.picture ||
                              'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                              " class="h-16 w-16 rounded-md mx-auto" />
                          </div>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <b>{{ it.product.name }}</b>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <span>{{ it.quantity }}</span>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <table class="min-w-full">
                            <thead>
                              <tr>
                                <th
                                  class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                  {{ $t("ok_good") }}
                                </th>
                                <th v-if="CheckQteReturnedexist"
                                  class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                  {{ $t("defective") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              <tr>
                                <td class="px-6 py-4 border whitespace-no-wrap">
                                  <div class="flex items-center">
                                    <input id="Defective" :disabled="CheckQteReturnedexist" type="number"
                                      :max="it.quantity" min="0" v-model="it.product.qteChange"
                                      class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full" />
                                  </div>
                                </td>
                                <td v-if="CheckQteReturnedexist" class="px-6 py-4 border whitespace-no-wrap">
                                  <div class="flex items-center">
                                    {{ it.quantity - it.product.qteChange }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end shipping cancelled -->
        <!--*******************  Product change ***************************************-->
        <div v-if="formData.type == 'change'"
          class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
          <div class="flex w-full mt-1">
            <span class="rounded-full h-12 w-12 bg-gray-200">
              <i class="material-icons p-3 text-green-400">local_mall</i>
            </span>
            <div class="ml-5 w-full pb-4">
              <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                {{ $t("products_change") }}
              </div>
              <div class="w-full space-y-4">
                <div class="w-full">
                  <table class="min-w-full w-full">
                    <thead class="text-white">
                      <tr
                        class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                        <th class="p-2 justify-center">{{ $t("image") }}</th>
                        <th class="p-2 justify-center">{{ $t("name") }}</th>
                        <th class="p-2 justify-center">{{ $t("quantity") }}</th>
                        <th class="p-2 justify-center">
                          {{ $t("quantity_returned") }}
                        </th>
                        <th v-if="CheckQteDefecteuseexist" class="p-2 justify-center">
                          {{ $t("quantity_defective") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="flex-1 sm:flex-none">
                      <tr v-for="(it, index) in formData.order.details" :key="index"
                        class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                          <div>
                            <img :alt="it.product.name" :src="it.product.picture ||
                              'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                              " class="h-16 w-16 rounded-md mx-auto" />
                          </div>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <b>{{ it.product.name }}</b>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <b>{{ it.quantity }}</b>
                        </td>
                        <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <input id="Defective" :disabled="CheckQteDefecteuseexist" type="number" :max="it.quantity"
                            min="0" v-model="it.product.qteChange"
                            class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full" />
                        </td>
                        <td v-if="CheckQteDefecteuseexist"
                          class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                          <b>{{ it.quantity - it.product.qteChange }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  end product change -->
        <div class="mt-6 pt-3 flex justify-center p-10">
          <button
            class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
            @click="save">
            {{ $t("save") }}
          </button>
        </div>
        <div class="flex justify-center p-10">
          <button @click="startConfirmation" :class="$colors.newColor"
            class="rounded focus:outline-none outline-none btn-more text-white hover:text-red-500">
            {{ $t("more_info") }}
            <i class="material-icons" style="transition: 0.3s"
              :style="showMoreInfo ? 'transform: rotate(180deg)' : ''">keyboard_arrow_down</i>
          </button>
        </div>
        <div v-if="showMoreInfo">
          <div class="flex w-full space-x-2">
            <!-- ********************************************************Customer information **********************************-->
            <div class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
              <div class="flex w-full">
                <span class="rounded-full h-12 w-12 bg-gray-200"><i
                    class="material-icons p-3 text-green-400">person</i></span>
                <div class="ml-5 w-full">
                  <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                    {{ $t("customer_information") }}
                  </div>
                  <div class="text-sm font-light text-gray-500"></div>
                  <div class="w-full space-y-4">
                    <div class="flex">
                      <div
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="customerFullName" class="bg-white text-gray-700 px-1">{{ $t("full_name") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <input id="customerFullName" disabled type="text" v-model="formData.order.customer.fullName"
                            class="py-2 text-xs px-2 outline-none block h-full w-full" />
                        </p>
                      </div>
                    </div>
                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="customerPhone" class="bg-white text-gray-700 px-1">{{ $t("phone") }}
                            <span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <input id="customerPhone" disabled type="text" v-model="formData.order.customer.phone"
                          class="py-2 text-xs px-2 outline-none block h-full w-full" />
                      </p>
                    </div>
                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="customerAddress" class="bg-white text-gray-700 px-1">{{ $t("adress") }}
                            <span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <textarea id="customerAddress" disabled type="text" v-model="formData.order.customer.address"
                          class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                      </p>
                    </div>
                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="customerShippingAddress" class="bg-white text-gray-700 px-1">{{
                            $t("shipping_address") }}
                            <span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <textarea id="customerShippingAddress" disabled type="text"
                          v-model="formData.order.customer.shippingAddress"
                          class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full"></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ****************************** Order Information *********************************************-->
            <div class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
              <div class="flex w-full mt-2">
                <span class="rounded-full h-12 w-12 bg-gray-200">
                  <i class="material-icons p-3 text-green-400">local_grocery_store</i>
                </span>

                <div class="ml-5 w-full">
                  <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                    {{ $t("order_information") }}
                  </div>
                  <div class="text-sm font-light text-gray-500"></div>
                  <div class="w-full space-y-4">
                    <div class="flex">
                      <div
                        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="source" class="bg-white text-gray-700 px-1">{{ $t("order_source") }}
                              <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                          <input id="source" disabled type="text" v-model="formData.order.source"
                            class="py-2 text-xs px-2 outline-none block h-full w-full" />
                        </p>
                      </div>
                    </div>

                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="sourceLink" class="bg-white text-gray-700 px-1">{{ $t("order_source_link")
                            }}<span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <input id="sourceLink" disabled type="text" v-model="formData.order.sourceLink"
                          class="py-2 text-xs px-2 outline-none block h-full w-full" />
                      </p>
                    </div>

                    <div
                      class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="date" class="bg-white text-gray-700 px-1">{{ $t("order_date") }}
                            <span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <input id="date" disabled type="datetime-local" v-model="formData.order.date"
                          class="py-2 text-xs px-2 outline-none block h-full w-full" />
                      </p>
                    </div>

                    <div v-if="
                      currentUser.type == 'Admin' || currentUser.type == 'CEO'
                    "
                      class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="seller" class="bg-white text-gray-700 px-1">{{ $t("seller") }}
                            <span :class="$colors.required">*</span></label>
                        </p>
                      </div>
                      <p>
                        <input id="date" disabled type="text" v-model="formData.seller.fullName"
                          class="py-2 text-xs px-2 outline-none block h-full w-full" />
                        <!-- <select id="type" disabled name="seller" autocomplete="type" v-model="formData.order.seller" @change="ViderTable" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                                    <option selected disabled :value="null">{{$t('seller')}}</option>
                                                    <option v-for="seller in sellers" :key="seller._id" :value="seller._id">
                                                        {{ seller.fullName }}
                                                    </option>
                                                </select> -->
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ********************************************************Product Table assigned to order **********************************-->
          <div class="flex w-full">
            <div class="flex space-x-2 w-full shadow-xs mt-1 rounded-lg mb-10">
              <div class="flex w-full justify-between items-center h-auto p-6">
                <div class="flex w-full">
                  <span class="rounded-full h-12 w-12 bg-gray-200">
                    <i class="material-icons p-3 text-green-500">local_mall</i>
                  </span>
                  <div class="ml-3 w-full p-2 pb-8">
                    <div class="w-full flex text-base font-semibold text-gray-600">
                      <p class="w-1/2">{{ $t("order") }}</p>
                    </div>
                    <div class="mt-4 overflow-x-auto">
                      <table v-if="formData.order.details" class="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th
                              class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                              {{ $t("image") }}
                            </th>
                            <th
                              class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                              {{ $t("name") }}
                            </th>
                            <th
                              class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                              {{ $t("unit_price") }}
                            </th>
                            <th
                              class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                              {{ $t("quantity") }}
                            </th>
                            <th
                              class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                              {{ $t("amount") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in formData.order.details" :key="index">
                          <tr>
                            <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                <img :src="item.product.picture ||
                                  'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                  " class="h-16 w-16 rounded-md" />
                              </div>
                            </td>
                            <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                {{ item.product.name }}
                              </div>
                            </td>

                            <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                {{ item.unitPrice }}
                                <sup class="text-black">{{
                                  currentCurrency
                                }}</sup>
                              </div>
                            </td>

                            <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div class="flex space-x-6">
                                <span :class="$colors.textPrimary"
                                  class="px-1 text-gray-700 text-sm rounded-md w-4 h-6 flex items-center focus:outline-none">
                                  {{ item.quantity }}
                                </span>
                              </div>
                              <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"></div>
                            </td>
                            <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                {{ item.unitPrice * item.quantity }}
                                <sup class="text-black">{{
                                  currentCurrency
                                }}</sup>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="5" class="px-6 text-right py-2 whitespace-no-wrap border-gray-200">
                              <div class="text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900">
                                {{ $t("total_price") }}: {{ TotalPrice() }}
                                <sup class="text-black">{{
                                  currentCurrency
                                }}</sup>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ************************************************************************************************-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        order: {
          customer: {},
          seller: {},
        },
      },
      currentUser: {
        role: {},
      },
      searchText: "",
      quantity: 0,
      couriers: [],
      status: [],
      products: [],
      show_modal: true,
      CheckQteReturnedexist: false,
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      productselected: [],
      numberItemRepeated: 0,
      sellers: [],
      zones: [],
      courier: [],
      statusShipping: [
        "to prepare",
        "outofstock",
        "prepared",
        "processed",
        "shipped",
        "delivered",
        "paid",
        "cancelled",
        "refused",
        "remind",
        "unreachable",
        "return",
      ],
      typesShipp: ["pendingchange", "change"],
      showMoreInfo: false,
      CheckQteDefecteuseexist: false,
      sellerExists: false,
      options: this.$countries,
      currentCurrency: "",
      idWharhouse: null,
    };
  },
  async mounted() {
    await this.getUser();
    if (this.currentUser.type != "Admin") {
      this.getSellers();
    } else {
      this.formData.order.seller = this.currentUser._id;
      //
    }

    await this.getStatus();
    await this.GetShipping();
    await this.getProducts({});
    await this.getZones();
    await this.getCouriers();
    //await this.getCurrentCourierZone(this.formData.zone._id);
    await this.CheckQteDefecteuse();
  },
  methods: {
    RouterBack: function () {
      this.$router.back();
    },
    async getCurrencyBycontry(country) {
      const resW = await this.$server.get("warehouses", { country: country });
      this.currentCurrency = resW.content.currency;
      this.idWharhouse = resW.content._id;
    },
    TotalPrice() {
      let Total = 0;
      for (const element of this.formData.order.details) {
        Total += element.unitPrice * element.quantity;
      }
      return Total;
    },
    async CheckQteDefecteuse() {
      this.CheckQteDefecteuseexist = false;
      if (this.formData.type == "change") {
        for (let i in this.formData.order.details) {
          const filters = {
            product: this.formData.order.details[i].product._id,
            order: this.formData.order._id,
            shipping: this.formData._id,
          };
          const res = await this.$server.search("stockHistories", filters);

          if (typeof res.content.results !== "undefined") {
            this.formData.order.details[i].product.qteChange =
              res.content.results[0].quantity;
            this.CheckQteDefecteuseexist = true;
          }
        }
      }
    },
    async getCouriers() {
      const filter = {
        type: "Courier",
        countries: this.formData.country,
        limit: this.limit,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.couriers = res.content.results;
      else this.couriers = [];
    },
    searchCouriers: _.debounce(async function (search) {
      const filter = {
        type: "Courier",
        limit: this.limit,
        countries: this.formData.country,
        name: search,
      };
      const res = await this.$server.find("users", filter);
      if (res.content.results) this.couriers = res.content.results;
      else this.couriers = [];
    }, 500),
    async getCurrentCourierZone(id) {
      const filter = {
        type: "Courier",
        zones: id,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) {
        this.courier = res.content.results;
      } else this.courier = [];
    },
    async getCourierZone(event) {
      const filter = {
        type: "Courier",
        zones: event.target.options[event.target.options.selectedIndex].id,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) {
        this.courier = res.content.results;
      } else this.courier = [];
    },
    startConfirmation() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    async getZones() {
      const res = await this.$server.search("zones");
      if (res.content.results) this.zones = res.content.results;
      else this.zones = [];
    },
    async getUser() {
      const res = await this.$server.me("users");
      //
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async getProducts(filters) {
      if (this.formData.order.seller)
        filters.seller = this.formData.order.seller._id;

      const res = await this.$server.search("products", filters);
      if (res.content.results) {
        this.products = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;

        for (let i in this.products) {
          this.products[i].quantity = this.products[i].quantity.inStock;
          /*if (this.products[i].category)
                          this.products[i].category = this.products[i].category.name;*/
          if (this.products[i].seller)
            this.products[i].seller = this.products[i].seller.username;
        }
      } else this.products = [];
    },

    async getStatus() {
      const res = await this.$server.search("status");
      if (res.content.results) this.status = res.content.results;
      else this.status = [];
    },
    async save() {
      // this.formData.order.seller = this.formData.order.seller;
      this.$swal.fire({
        title: `Are you sure to update this shipping (status : ${this.formData.status})`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await this.$server.update("shippings", this.formData);
          if (data && data._id) {
            alert(this.$t("shippings_updated"), "success");
            await this.GetShipping();
          } else {
            alert(data, "warning");
            await this.GetShipping();
          }
        }
      });
    },

    checkData() {
      if (
        this.formData.order.date &&
        this.formData.order.details.length > 0 &&
        this.formData.order.customer
      )
        return true;
      else return false;
    },

    removeProduct(data, index) {
      this.$swal.fire({
        title: this.$t("confirm_delete_product"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let pos = this.formData.order.details
            .map(function (e) {
              return e.product._id;
            })
            .indexOf(data._id);

          this.formData.order.details.splice(pos, 1);
          data.product.check = false;
          alert(this.$t("product_deleted"), "success");
        }
      });
    },

    addProductsToTemp(data, index) {
      if (data.check) {
        const detail = {
          product: data,
          unitPrice: 1,
          category: data.category._id,
          quantity: 1,
        };

        let found = 0;
        for (let i = 0; i < this.formData.order.details.length; i++) {
          if (this.formData.order.details[i].product._id == data._id) {
            found = found + 1;
            this.numberItemRepeated = this.numberItemRepeated + 1;
          }
        }
        if (found >= 1) {
        } else {
          this.productselected.push(detail);
        }
      } else {
        let pos = this.productselected
          .map(function (e) {
            return e.product._id;
          })
          .indexOf(data._id);
        this.productselected.splice(pos, 1);
      }
    },

    addQuantity(index) {
      this.formData.order.details[index].quantity++;
    },
    removeQuantity(index) {
      if (this.formData.order.details[index].quantity <= 1) {
      } else {
        this.formData.order.details[index].quantity--;
      }
    },
    ViderTable() {
      //
      this.productselected = [];
    },
    async getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    async GetShipping() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("shippings", {
          id: this.$route.params.id,
        });

        this.formData = editData.content;
        this.formData.order.date = this.$moment(
          this.formData.order.date
        ).format("yyyy-MM-DDTHH:mm");
        this.formData.date = this.$moment(this.formData.date).format(
          "yyyy-MM-DD"
        );
        await this.getCurrencyBycontry(this.formData.country);
        let dataWhrahouseSetting = null;
        if (this.formData.seller.settings.length > 0)
          dataWhrahouseSetting = await this.getElementInArray(
            this.formData.seller.settings,
            this.idWharhouse
          );

        if (
          !dataWhrahouseSetting ||
          (dataWhrahouseSetting && dataWhrahouseSetting.shipping)
        ) {
          if (this.currentUser.type == "StockManager")
            this.statusShipping = [
              "to prepare",
              "prepared",
              "refused",
              "cancelled",
              "return",
            ];
          if (this.currentUser.type == "Livreur")
            this.statusShipping = [
              "prepared",
              "to pick",
              "picked",
              "refused",
              "cancelled",
            ];
        } else {
          if (this.currentUser.type == "StockManager")
            this.statusShipping = [
              "to prepare",
              "prepared",
              "refused",
              "cancelled",
              "return",
            ];
          if (this.currentUser.type == "Livreur")
            this.statusShipping = [
              "prepared",
              "shipped",
              "delivered",
              "refused",
              "cancelled",
            ];
        }
        if (this.currentUser.type == "Accountant")
          this.statusShipping = ["delivered", "paid"];
        await this.chechQteReturned();
      }
    },
    scrollTo(hash) {
      location.hash = "#" + hash;
    },
    async onChangeStatus(event) {
      if (event.target.value === "return") {
        await this.chechQteReturned();
        this.scrollTo(this.formData._id);
      }
    },
    async chechQteReturned() {
      if (this.formData.status == "return") {
        for (let i in this.formData.order.details) {
          const filters = {
            product: this.formData.order.details[i].product._id,
            order: this.formData.order._id,
            shipping: this.formData._id,
          };
          const res = await this.$server.search("stockHistories", filters);

          if (typeof res.content.results !== "undefined") {
            this.formData.order.details[i].product.qteChange =
              res.content.results[0].quantity;
            this.CheckQteReturnedexist = true;
          }
        }
      }
    },
    async getSellers() {
      const filter = {
        type: "Seller",
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) {
        this.sellers = res.content.results;
        this.sellerExists = true;
      } else this.sellers = [];
    },
    searchSellers: _.debounce(async function (search, event) {
      const filter = {
        type: "Seller",
        limi: this.limit,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) {
        this.sellers = res.content.results;
      } else this.sellers = [];
    }, 500),
  },

  computed: {
    resultSearchUsers() {
      if (this.searchText) {
        return this.products.filter((product) => {
          var regex = new RegExp(this.searchText, "i");

          return regex.test(product.name);
        });
      } else {
        return this.products;
      }
    },
  },

  /************************************* Pagination **************************************************/
};
</script>

<style>
.btn-more i {
  display: block;
}

.toggle__dot {
  transition: all 0.3s ease-in-out;
}

.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}

input:checked~.toggle__dot {
  transform: translateX(100%);
  background-color: #68d391;
}

/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
